import {
  Button,
  Col,
  Container,
  FormCheck,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  Row,
} from "react-bootstrap";

import { GoHeart, GoHeartFill } from "react-icons/go";

import gallery1 from "./assets/images/IMG_0736.webp";
import gallery2 from "./assets/images/IMG_0798.webp";
import calendar from "./assets/images/calendar.png";
import church from "./assets/images/church.jpeg";

import rest2 from "./assets/images/rest2.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import { useState } from "react";
import axios from "axios";

const SignupSchema = Yup.object().shape({
  fullName: Yup.string().required("Այս դաշտը լրացնելը պարտադիր է:"),
  guestsCount: Yup.number()
    .nullable(true)
    .required("Այս դաշտը լրացնելը պարտադիր է:"),
});

function App() {
  const {
    handleSubmit,
    handleChange,
    values,
    setFieldValue,
    isSubmitting,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      fullName: "",
      areYouGoing: "yes",
      guestsCount: 1,
      preferedSong: "",
    },
    validationSchema: SignupSchema,
    onSubmit: async (data) => {
      console.log(data);
      await axios.post(
        "https://devclient.logoom.io/api/data/create",
        {
          group: "Wedding",
          message: data.fullName,
          metadata: JSON.stringify(data),
          category: "Info",
          sourceKey: "and_man",
        },
        {
          headers: {
            apikey: "da75c1f1-8b22-497d-8a2c-1b0ded34771b",
            apisecret: "ed23dc3c-7143-4f8f-b62e-3d567411e6f6",
          },
        }
      );
      let text =
        data.areYouGoing === "yes"
          ? "Շնորհակալություն: Սիրով կսպասենք Ձեզ:"
          : "Հուսով ենք Ձեր պլանները կփոխվեն: Ուրախ կլինենք Ձեզ նույնպես տեսնել մեր հարսանեկան տոնակատարությանը:";
      // toast(text, {
      //   position: "top-center",
      //   hideProgressBar: true,
      //   theme: "colored",
      // });
      setThanksText(text);
      handleShow();
      resetForm();
    },
  });

  const [show, setShow] = useState(false);
  const [thanksText, setThanksText] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton className="border-0">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">{thanksText}</p>
        </Modal.Body>
      </Modal>
      <section id="ourwedding" className="bg-primary" style={{}}>
        <Container style={{ zIndex: 3, padding: "50px 0" }}>
          <Row>
            <Col md={12} className="text-center">
              <h1 className="text-white mb-3 fw-bold">
                ՄԵՐԻ
                <span className="d-block my-2">
                  <GoHeart className="mb-1" />
                </span>
                ԱՆԴՐԱՆԻԿ
              </h1>
            </Col>
          </Row>
        </Container>
        {/* <Container style={{ zIndex: 3, padding: "50px 0" }}>
          <Row>
            <Col md={12} className="text-center">
              <h3 className="text-white mb-5">
                <span className="d-inline-block mb-2">
                  03 օգոստոս 2024 թ., 18:00
                </span>
                <span className="px-4 d-none d-sm-inline-block">|</span>
                Ռեստորան <i className="fw-bold">«Սաֆիսսա»</i>
              </h3>
              <Button variant="secondary" size="lg">
                ԵՍ ԿԳԱՄ
              </Button>
            </Col>
          </Row>
        </Container> */}
      </section>
      <section id="howwemeet">
        <Container className="text-center">
          <h1 className="mb-3">ՍԻՐԵԼԻ ՀՅՈՒՐԵՐ</h1>
          <div className="mb-3">
            <GoHeartFill size={20} color="#bdb7b1" />
          </div>
          <p className="mb-5">
            Մեր կյանքում սպասվում է մեծ փոփոխություն: Ցանկանում ենք, որ այդ
            հիասքանչ օրը մեր կողքին լինեն մեզ հարազատ եւ թանկագին մարդիկ: Ուրախ
            կլինենք Ձեզ հետ կիսելու մեզ համար այս կարեւոր օրը:
          </p>

          <div className="mx-5 mx-md-0 mb-2">
            <img
              src={gallery2}
              alt=""
              className="img-fluid"
              style={{
                maxHeight: 400,
              }}
            />
          </div>
          <div className="mx-5 mx-md-0 position-relative">
            <div className="d-inline-block position-relative">
              <img
                src={calendar}
                alt=""
                className="img-fluid"
                style={{
                  maxHeight: 400,
                }}
              />
            </div>
          </div>
        </Container>
      </section>

      <section id="locations">
        <Container className="text-center">
          <h1 className="mb-3 text-uppercase" style={{ letterSpacing: 5 }}>
            Locations
          </h1>
          <Row>
            <Col lg={6}>
              <div className="mb-5">
                <div className="mb-3">
                  <img
                    src={church}
                    alt=""
                    className="img-fluid"
                    style={{
                      maxWidth: 150,
                    }}
                  />
                </div>
                <h1 className="mb-3">ԵԿԵՂԵՑԻ</h1>
                <div className="mb-3">
                  <GoHeartFill size={20} color="#bdb7b1" />
                </div>
                <h3 className="mb-3">Սուրբ Ամենափրկիչ</h3>
                <h3 className="mb-5">15:00</h3>
                <a
                  className="btn btn-outline-secondary btn-lg btn-block"
                  href="https://yandex.ru/maps?rtext=40.189306%2C44.514781~40.301812%2C44.585442&rtt=auto"
                >
                  Քարտեզ
                </a>
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-5">
                <div className="mb-3">
                  <img
                    src={rest2}
                    alt=""
                    className="img-fluid"
                    style={{
                      maxWidth: 150,
                    }}
                  />
                </div>
                <h1 className="mb-3">ՌԵՍՏՈՐԱՆ</h1>
                <div className="mb-3">
                  <GoHeartFill size={20} color="#bdb7b1" />
                </div>
                <h3 className="mb-3">"SAFISSA" RESTAURANT COMPLEX</h3>
                <h3 className="mb-5">18:00</h3>
                <a
                  className="btn btn-outline-secondary btn-lg btn-block"
                  href="https://yandex.ru/maps/org/safissa/173774822631"
                >
                  Քարտեզ
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="gallery">
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={9}>
              <div className="mx-4 mx-md-0">
                <img
                  src={gallery1}
                  alt="gallery 1"
                  className="w-100 shadow-1-strong rounded mb-4"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="welcome">
        <Container>
          <div className="text-center">
            <h1 className="mb-3">Սիրով սպասում ենք Ձեզ</h1>
            <div className="mb-3">
              <GoHeartFill size={20} color="#bdb7b1" />
            </div>
          </div>

          <Row className="justify-content-center">
            <Col md={9} lg={6}>
              <form onSubmit={handleSubmit}>
                <FormGroup className="mb-3">
                  <FormLabel>Անուն Ազգանուն</FormLabel>
                  <FormControl
                    className="input-v1"
                    name="fullName"
                    onChange={handleChange}
                    value={values.fullName}
                    isInvalid={!!errors.fullName}
                  />
                  <FormControl.Feedback type="invalid">
                    {errors?.fullName || ""}
                  </FormControl.Feedback>
                </FormGroup>

                <FormGroup className="mb-3">
                  <FormLabel>Կկարողանա՞ք ներկա գտնվել</FormLabel>
                  <FormCheck // prettier-ignore
                    type="radio"
                    value="yes"
                    name="areYouGoing"
                    id="areYouGoingYes"
                    label="Այո"
                    onChange={(e) => {
                      setFieldValue(e.target.name, e.target.value);
                    }}
                    checked={values.areYouGoing === "yes"}
                  />
                  <FormCheck // prettier-ignore
                    type="radio"
                    value="no"
                    name="areYouGoing"
                    id="areYouGoingNo"
                    label="Ոչ"
                    onChange={(e) => {
                      setFieldValue(e.target.name, e.target.value);
                    }}
                    checked={values.areYouGoing === "no"}
                  />
                </FormGroup>

                {values.areYouGoing === "yes" ? (
                  <>
                    <FormGroup className="mb-3">
                      <FormLabel>Մասնակիցների քանակ</FormLabel>
                      <FormControl
                        className="input-v1"
                        name="guestsCount"
                        type="number"
                        onChange={handleChange}
                        value={values.guestsCount}
                        isInvalid={!!errors.guestsCount}
                      />
                      <FormControl.Feedback type="invalid">
                        {errors?.guestsCount || ""}
                      </FormControl.Feedback>
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <FormLabel>Ձեր նախընտրած երգը</FormLabel>
                      <FormControl
                        className="input-v1"
                        as="textarea"
                        rows={3}
                        name="preferedSong"
                        onChange={handleChange}
                        value={values.preferedSong}
                      />
                    </FormGroup>
                  </>
                ) : null}

                <div className="text-center">
                  <Button
                    variant="secondary"
                    size="lg"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Ուղարկել
                  </Button>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="thankyou">
        <Container className="text-center">
          <h1 className="mb-3">Շնորհակալություն</h1>
          <div className="mb-3">
            <GoHeartFill size={20} color="#bdb7b1" />
          </div>

          <Row className="justify-content-center">
            <Col md={9} lg={6}>
              <p>
                Ուրախ կլինենք տեսնել Ձեզ մեր հարսանեկան հանդիսությանը: Վերցրեք
                Ձեզ հետ տոնական տրամադրությունն ու հարմար կոշիկներ պարելու
                համար:
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <footer className="footer">
        <Container>
          <p className="mb-0 text-white text-center">
            © 2024 Մենք ամուսնանում ենք: Բոլոր իրավունքները պաշտպանված են!!!
          </p>
        </Container>
      </footer>

      <ToastContainer />
    </>
  );
}

export default App;
